<template>
  <div
    class="inner_cont">
   <div
    :class="[
      'result_body',
      rewardData.checked_reward ? 'result_reward_y' : 'result_reward_n',
      selectedAnswerItem.checked_answer ? 'result_correct' : 'result_wrong'
    ]">
      <!-- 금액 뱃지 -->
      <div
        v-if="rewardData.checked_reward && !selectedAnswerItem.checked_answer"
        class="txt_delete">
        <span class="inner_txt_delete">{{ $poData.quiz_explanation_money_reward.format(this.rewardData.success_point) }}</span>
      </div>
      <span class="badge_price">{{ badgeText }}</span>
      <!-- 결과 -->
      <div class="area_get" ref="areaGet">
        <!-- 이미지 -->
        <div
          id="imgResult"
          class="img_result">
          <Lottie
            class="lottie_result_img"
            :options="resultImgLottieOption"
            @animCreated="handleAnimation" />
          <Lottie
            v-if="isShowFireworks"
            class="lottie_result_fireworks"
            :options="resultFireworksOption"
            @animCreated="handleAnimation" />
        </div>
      </div>
      <!-- 문구 -->
      <p class="desc_result">{{ selectedAnswerItem.checked_answer ? $poData.quiz_explanation_title_correct : $poData.quiz_explanation_title_wrong }}</p>
   </div>
  </div>
</template>

<script>
import Lottie from 'vue-lottie';
import card from '@/assets/json/card.json';
import expect from '@/assets/json/expect.json';
import error from '@/assets/json/error.json';
import fireworks from '@/assets/json/fireworks.json';
export default {
  name: 'Result',
  props:{
    quizContentType: String,
    selectedAnswerItem: Object,
    rewardData: Object,
  },
  beforeMount(){
    this.setAnimationData()
  },
  mounted(){
    this.autoGoNext()
  },
  components:{
    Lottie
  },
  data(){
    return{
      resultImgLottieOption: {
        animationData: {}
      },
      resultFireworksOption: {
        animationData: fireworks,
        // loop: false,
      },
      autoGoNextSpeed: 2000
    }
  },
  computed:{
    isAll(){
      return this.quizContentType === 'AUTOCHECK'
    },
    resultTitle(){
      if(this.isAll){
        return this.$poData.quiz_explanation_title_3;
      }
      return this.selectedAnswerItem.checked_answer ? this.$poData.quiz_explanation_title_1 : this.$poData.quiz_explanation_title_2
    },
    badgeText(){
      // 보상 있음
      if(this.rewardData.checked_reward){
        // 정답
        if(this.selectedAnswerItem.checked_answer){
          return this.$poData.quiz_explanation_money_reward.format(this.rewardData.success_point);
        // 오답
        }else{
          return this.$poData.quiz_explanation_money_reward.format(this.rewardData.fail_point)
        }
      // 보상 없음
      }else{
        // 정답
        if(this.selectedAnswerItem.checked_answer){
          return this.$poData.quiz_explanation_no_reward_correct;
        // 오답
        }else{
          return this.$poData.quiz_explanation_no_reward_wrong;
        }
      }
    },
    isShowFireworks(){
      return this.rewardData.checked_reward && this.selectedAnswerItem.checked_answer; // 보상있고 정답일때
    }
  },
  methods:{
    setAnimationData(){
      // 보상 있음
      if(this.rewardData.checked_reward){
        this.resultImgLottieOption.animationData = card
      // 보상 없음
      }else{
        // 정답
        if(this.selectedAnswerItem.checked_answer){
          this.resultImgLottieOption.animationData = expect
        // 오답
        }else{
          this.resultImgLottieOption.animationData = error
        }
      }
    },
    handleAnimation(anim) {
      this.anim = anim;
    },
    autoGoNext(){
      var _this = this
      this.timeoutID1 = setTimeout(function(){
        this.timeoutID1 = null;
        _this.$emit('goNextPage')
      }, this.autoGoNextSpeed);
    }
  }
}
</script>

<style scoped>
.inner_cont{flex:1 1 100%;max-height:100%}
.result_body{display:flex !important;flex-direction:column;position:relative;height:100%;padding-top:24px;text-align:center;box-sizing:border-box}
/* .result_body .tit_result{flex:0 1;display:block;margin-bottom:12px;font-family:'Poppins';font-weight:700;font-size:34px;line-height:40px;color:#232323} */
.result_body .area_get{flex:0 1 100%;padding-top:24px;font-size:0;white-space:nowrap;text-align:center}
.result_body .area_get:after{display:inline-block;height:100%;vertical-align:middle;content:''}
.result_body .badge_price{display:inline-block;color:#232323;vertical-align:top}
.result_body .desc_result{margin-top:24px;font-family:'Poppins';font-weight:700;font-size:28px;line-height:34px}
.result_body .lottie_result_fireworks{position:absolute;top:35%;left:50%;width:150% !important;height:auto !important;transform: translate(-50%, -50%);}
/* -- 보상 있음 -- */
.result_reward_y .badge_price{font-weight:700;font-size:28px;line-height:36px;
  animation-name: priceEntranceToCard;
  animation-duration:0.8s;
  animation-delay:0.8s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
.result_reward_y .desc_result{color:#fff}
.result_reward_y.result_wrong .txt_delete{display:block;margin-bottom:2px}
.result_reward_y.result_wrong .txt_delete .inner_txt_delete{display:inline-block;position:relative;font-weight:700;font-size:16px;line-height:24px;vertical-align:top;color:#E23C3C;
  animation-name: priceDeleteFadeOut;
  animation-duration:0.8s;
  animation-delay:0.8s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
.result_reward_y.result_wrong .txt_delete .inner_txt_delete:after{position:absolute;top:12px;left:0;width:0;height:1px;background-color:#E23C3C;content:'';
  animation-name: priceDeleteLineShow;
  animation-duration:0.8s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
/* -- 보상 없음 -- */
.result_reward_n .badge_price{align-self:center;display:block;padding:6px 8px;border-radius:4px;font-weight:700;font-size:20px;line-height:28px;background-color:#BEA9ED}
/* 문구 */
.result_reward_n .desc_result{color:#8F69DD}
/* -- 공통 -- */
.result_body #imgResult.img_result{display:inline-block;position:relative;z-index:2;width:100%;height:100%;max-width:260px;max-height:260px;background-size:contain;vertical-align:middle;white-space:normal}

/* ---- 높이 640 이하 디바이스 ---- */
/* .short_device .inner_cont{height:286px} */
.short_device .result_body .area_get{padding-top:8px}
.short_device .result_body .desc_result{margin-top:8px}
.short_device .result_body #imgResult.img_result{width:100%;height:100%;max-width:200px;max-height:200px;background-size:contain;}

@keyframes priceDeleteLineShow {
  0%{
    width:0;
  }
  100%{
    width:100%
  }
}

@keyframes priceDeleteFadeOut {
  0%{
    opacity:1;
  }
  100%{
    opacity:0
  }
}

@keyframes priceEntranceToCard {
  0%{
    transform:translateY(0);opacity:1;
  }
  100%{
    transform:translateY(80px);opacity:0
  }
}
</style>